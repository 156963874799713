import globalVars from '../variables/globalVars';

export function checkAccess(accessType) {
    if (globalVars.currentLogin[accessType] !== undefined)
    {
        return globalVars.currentLogin[accessType];
    }
    else
    {
        return false;
    }
}