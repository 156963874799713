import { ReactComponent as YourSvg } from '../images/caspa.svg'
import { useState, useEffect } from 'react'
import Lottie from 'lottie-react'
import animationData1 from '../lotties/SplashScreen1.json'
import animationData2 from '../lotties/SplashScreen2.json'
import animationData3 from '../lotties/SplashScreen3.json'
import animationData4 from '../lotties/SplashScreen4.json'
import animationData5 from '../lotties/SplashScreen5.json'
import animationData6 from '../lotties/SplashScreen6.json'
import animationData7 from '../lotties/SplashScreen7.json'
import animationData8 from '../lotties/SplashScreen8.json'
import animationData9 from '../lotties/SplashScreen9.json'
import animationData10 from '../lotties/SplashScreen10.json'
import animationData11 from '../lotties/SplashScreen11.json'
import animationData12 from '../lotties/SplashScreen12.json'
import animationData13 from '../lotties/SplashScreen13.json'
import animationData14 from '../lotties/SplashScreen14.json'
import animationData15 from '../lotties/SplashScreen15.json'
import globalVars from '../variables/globalVars'

function Welcome() {
    const [newWidth, setNewWidth] = useState(Math.round((window.innerWidth - 300) / 2))
    const [newHeight, setNewHeight] = useState(Math.round((window.innerHeight - 300) / 2))
    const min = 1
    const max = 15
    const rand = min + Math.floor(Math.random() * (max - min))

    let animationData = animationData1

    globalVars.setCustomerRow('')
    globalVars.setCustomerTabIndex(0)

    globalVars.setContactRow('')
    globalVars.setCustomerVersionRow('')
    globalVars.setCustomerNotesRow('')

    globalVars.setProductRow('')
    globalVars.setProductVersionRow('')
    globalVars.setProductTabIndex(0)

    globalVars.setModuleRow('')

    globalVars.setVersionRow('')

    switch (rand) {
        case 1:
            animationData = animationData1
            break
        case 2:
            animationData = animationData2
            break
        case 3:
            animationData = animationData3
            break
        case 4:
            animationData = animationData4
            break
        case 5:
            animationData = animationData5
            break
        case 6:
            animationData = animationData6
            break
        case 7:
            animationData = animationData7
            break
        case 8:
            animationData = animationData8
            break
        case 9:
            animationData = animationData9
            break
        case 10:
            animationData = animationData10
            break
        case 11:
            animationData = animationData11
            break
        case 12:
            animationData = animationData12
            break
        case 13:
            animationData = animationData13
            break
        case 14:
            animationData = animationData14
            break
        case 15:
            animationData = animationData15
            break
        default:
            animationData = animationData5
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)

        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // initialize event listeners on-mount & clean on-unmount

    useEffect(() => {}, [newWidth, newHeight])

    function handleResize() {
        setNewWidth(Math.round((window.innerWidth - 300) / 2))
        setNewHeight(Math.round((window.innerHeight - 300) / 2))
    }

    const contentStyle = {
        display: 'block',
        marginLeft: newWidth,
        marginTop: newHeight,
        minWidth: '320px',
    }

    return (
        <div style={contentStyle}>
            <div className="lottieapp" style={{ height: '300px', width: '300px' }}>
                <div className="login-form">
                    <div className="myHeading" style={{ display: 'flex', width: '250px' }}>
                        <YourSvg />
                        <h3 style={{ marginTop: '10px' }}>&nbsp;Welcome to CASPA</h3>
                    </div>
                    <div style={{ marginTop: '10px', fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}>Please select option from side bar</div>
                    <Lottie animationData={animationData} loop={true} height="300px" width="300px" />
                </div>
            </div>
        </div>
    )
}

export default Welcome
