import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyes } from '@fortawesome/pro-light-svg-icons'
import { faUser } from '@fortawesome/pro-light-svg-icons'
import { faCube } from '@fortawesome/pro-light-svg-icons'
import { faCubes } from '@fortawesome/pro-light-svg-icons'
import { faUnlock } from '@fortawesome/pro-light-svg-icons'
import { faChartLine } from '@fortawesome/pro-light-svg-icons'
import { faPieChart } from '@fortawesome/pro-light-svg-icons'
import { faFileSlash } from '@fortawesome/pro-light-svg-icons'
import { faDollarSign } from '@fortawesome/pro-light-svg-icons'
import { faScrewdriverWrench } from '@fortawesome/pro-light-svg-icons'
import { faUsers } from '@fortawesome/pro-light-svg-icons'
import { faDisplaySlash } from '@fortawesome/pro-light-svg-icons'
//import { faWrench } from '@fortawesome/pro-light-svg-icons'
import { faNewspaper } from '@fortawesome/pro-light-svg-icons'
//import { faCog } from '@fortawesome/pro-light-svg-icons'
import { faScreencast } from '@fortawesome/pro-light-svg-icons'
import { faArrowRightFromBracket, faSitemap } from '@fortawesome/pro-light-svg-icons'
import globalVars from '../variables/globalVars'
import { Link } from 'react-router-dom'
import { ReactComponent as YourSvg } from '../images/caspa.svg'
import { Tooltip } from 'primereact/tooltip'
import { checkAccess } from '../library/hasAccess'
import { Sidebar, Menu, MenuItem, SubMenu, sidebarClasses } from 'react-pro-sidebar'

export const MySideNav = ({ collapsed, toggled, handleToggleSidebar, handleCollapsedChange, defaultCollapsed, disabled, collapseSidebar }) => {
    // const { collapseSidebar } = useProSidebar()
    //const collapseSidebar = props.collapseSidebar

    const performLogoff = () => {
        globalVars.setJwt('')
        globalVars.setEmail('')
        globalVars.setToken('')
        globalVars.setCustomerRow('')
    }

    let sbCustomers = <></>
    let sbProducts = <></>
    let sbVersions = <></>
    let sbRemote = <></>
    let sbAccess = <></>
    let sbBoss = <></>
    let sbSchema = <></>

    if (globalVars.currentLogin.BOSSAccessLevel > 0) {
        sbBoss = (
            <>
                <MenuItem className="Boss" disabled={!disabled} icon={<FontAwesomeIcon icon={faEyes} size="2xl" />} component={<Link to="/BOSS" />} active={true}>
                    B.O.S.S.
                </MenuItem>{' '}
                <Tooltip target=".Boss" content="Back Office Service Sentry" />
            </>
        )
    }

    if (checkAccess('View Customers')) {
        sbCustomers = (
            <>
                <MenuItem className="Customers" disabled={!disabled} icon={<FontAwesomeIcon icon={faUser} size="2xl" />} component={<Link to="/Customers" />} active={true}>
                    Customers
                </MenuItem>{' '}
                <Tooltip target=".Customers" content="Customers" />
            </>
        )
    }
    if (checkAccess('View Products')) {
        sbProducts = (
            <>
                <MenuItem className="Products" disabled={!disabled} icon={<FontAwesomeIcon icon={faCube} size="2xl" />} component={<Link to="/Products" />}>
                    Products
                </MenuItem>
                <Tooltip target=".Products" content="Products" />
            </>
        )
    }
    if (checkAccess('View Versions')) {
        sbVersions = (
            <>
                <MenuItem className="Versions" disabled={!disabled} icon={<FontAwesomeIcon icon={faCubes} size="2xl" />} component={<Link to="/Versions" />}>
                    Versions
                </MenuItem>
                <Tooltip target=".Versions" content="Versions" />
            </>
        )
    }
    if (globalVars.currentLogin.SchemaAccessLevel > 0) {
        sbSchema = (
            <>
                <MenuItem className="Schema" disabled={!disabled} icon={<FontAwesomeIcon icon={faSitemap} size="2xl" />} component={<Link to="/Schema" />}>
                    Schema
                </MenuItem>
                <Tooltip target=".Schema" content="Schema" />
            </>
        )
    }
    if (checkAccess('View Remote Connections')) {
        sbRemote = (
            <>
                <MenuItem className="RemoteConnections" disabled={!disabled} icon={<FontAwesomeIcon icon={faScreencast} size="2xl" />} component={<Link to="/RemoteConnections" />}>
                    Remote Connections
                </MenuItem>
                <Tooltip target=".RemoteConnections" content="Remote Connections" />
            </>
        )
    }
    // if (checkAccess('System Maintenance') && globalVars.currentLogin.UserID === 45) {
    //     sbAccess = (
    //         <>
    //             <SubMenu className="System" disabled={!disabled} icon={<FontAwesomeIcon icon={faScrewdriverWrench} size="2xl" />} label="System">
    //                 <MenuItem className="Users" disabled={!disabled} icon={<FontAwesomeIcon icon={faUser} size="2xl" />} component={<Link to="/Users" />}>
    //                     Users
    //                 </MenuItem>
    //                 <MenuItem className="Groups" disabled={!disabled} icon={<FontAwesomeIcon icon={faUsers} size="2xl" />} component={<Link to="/Groups" />}>
    //                     Groups
    //                 </MenuItem>
    //                 <MenuItem className="Access" disabled={!disabled} icon={<FontAwesomeIcon icon={faDisplaySlash} size="2xl" />} component={<Link to="/Access" />}>
    //                     Access
    //                 </MenuItem>
    //                 <MenuItem className="Maintenance" disabled={!disabled} icon={<FontAwesomeIcon icon={faWrench} size="2xl" />} component={<Link to="/Maintenance" />}>
    //                     Maintenance
    //                 </MenuItem>
    //             </SubMenu>
    //             <Tooltip target=".System" content="System" />
    //             <Tooltip target=".Users" content="Users" />
    //             <Tooltip target=".Groups" content="Groups" />
    //             <Tooltip target=".Access" content="Access" />
    //             <Tooltip target=".Maintenance" content="Maintenance" />
    //         </>
    //     )
    // } else
    if (checkAccess('System Maintenance')) {
        sbAccess = (
            <>
                <SubMenu className="System" disabled={!disabled} icon={<FontAwesomeIcon icon={faScrewdriverWrench} size="2xl" />} label="System">
                    <MenuItem className="Users" disabled={!disabled} icon={<FontAwesomeIcon icon={faUser} size="2xl" />} component={<Link to="/Users" />}>
                        Users
                    </MenuItem>
                    <MenuItem className="Groups" disabled={!disabled} icon={<FontAwesomeIcon icon={faUsers} size="2xl" />} component={<Link to="/Groups" />}>
                        Groups
                    </MenuItem>
                    <MenuItem className="Access" disabled={!disabled} icon={<FontAwesomeIcon icon={faDisplaySlash} size="2xl" />} component={<Link to="/Access" />}>
                        Access
                    </MenuItem>
                </SubMenu>
                <Tooltip target=".System" content="System" />
                <Tooltip target=".Users" content="Users" />
                <Tooltip target=".Groups" content="Groups" />
                <Tooltip target=".Access" content="Access" />
            </>
        )
    }

    return (
        <Sidebar
            rootStyles={{
                [`.${sidebarClasses.container}`]: {
                    backgroundColor: 'White',
                    color: 'Black',
                    fontSize: '14px',
                    zIndex: 9999,
                },
            }}
            collapsed={collapsed}
            toggled={toggled}
            onToggle={handleToggleSidebar}
            breakPoint="sm"
            defaultCollapsed={defaultCollapsed}
            customBreakPoint="40px"
        >
            <Menu>
                <MenuItem className="menu1">
                    <YourSvg
                        onClick={() => {
                            collapseSidebar()
                            handleCollapsedChange()
                        }}
                    />
                </MenuItem>
                {sbCustomers}
                {sbProducts}
                {sbVersions}
                {sbRemote}
                <MenuItem className="SystemPasswords" disabled={!disabled} icon={<FontAwesomeIcon icon={faUnlock} size="2xl" />} component={<Link to="/SystemPasswords" />}>
                    System Passwords
                </MenuItem>
                <SubMenu className="Reporting" disabled={!disabled} icon={<FontAwesomeIcon icon={faChartLine} size="2xl" />} label="Reporting">
                    <MenuItem className="Dashboard" disabled={!disabled} icon={<FontAwesomeIcon icon={faPieChart} size="2xl" />} component={<Link to="/Dashboard" />}>
                        Dashboard
                    </MenuItem>
                    <MenuItem className="ForecastRevenue" disabled={!disabled} icon={<FontAwesomeIcon icon={faChartLine} size="2xl" />} component={<Link to="/ForecastRevenue" />}>
                        Forecast Revenue
                    </MenuItem>
                    <MenuItem className="NotInvoiced" disabled={!disabled} icon={<FontAwesomeIcon icon={faFileSlash} size="2xl" />} component={<Link to="/NotInvoiced" />}>
                        Not Invoiced
                    </MenuItem>
                    <MenuItem className="DeferredValue" disabled={!disabled} icon={<FontAwesomeIcon icon={faDollarSign} size="2xl" />} component={<Link to="/DeferredValue" />}>
                        Deferred Value
                    </MenuItem>
                </SubMenu>
                {sbAccess}
                {sbBoss}
                {sbSchema}
                <MenuItem className="WhatsNew" disabled={!disabled} icon={<FontAwesomeIcon icon={faNewspaper} size="2xl" />} component={<Link to="/WhatsNew" />}>
                    What's New
                </MenuItem>
                {/* <MenuItem className="Options" disabled={!disabled} icon={<FontAwesomeIcon icon={faCog} size="2xl" />} component={<Link to="/Options" />}>
                    Options
                </MenuItem> */}
                <MenuItem className="Logout" disabled={!disabled} icon={<FontAwesomeIcon icon={faArrowRightFromBracket} size="2xl" />} onClick={performLogoff}>
                    Logout
                </MenuItem>

                <Tooltip target=".SystemPasswords" content="System Passwords" />
                <Tooltip target=".Reporting" content="Reporting" />
                <Tooltip target=".Dashboard" content="Dashboard" />
                <Tooltip target=".ForecastReveue" content="Forecast Reveue" />
                <Tooltip target=".NotInvoiced" content="Not Invoiced" />
                <Tooltip target=".DeferredValue" content="Deferred Value" />
                <Tooltip target=".WhatsNew" content="What's New" />
                {/* <Tooltip target=".Options" content="Options" /> */}
                <Tooltip target=".Logoff" content="Logoff" />
            </Menu>
        </Sidebar>
    )
}

export default MySideNav
